import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Senders
import AllUsers from "../pages/senders/AllUsers"
import UsersProfile from "../pages/senders/UsersProfile"

// Partners
import Partners from "../pages/Partner/Partners"
import PartnerDetails from "../pages/Partner/PartnerDetails"
import Onboarding from "../pages/Partner/Onboarding"

// Orders
import AllOrders from "../pages/Orders/AllOrders"
import OrderDetails from "../pages/Orders/OrderDetails"

// Delivery Hub

// Transactions
import AllTransactions from "../pages/Transactions/AllTransactions"
import TransactionDetails from "../pages/Transactions/TransactionDetails"
import Refund from "../pages/Transactions/Refund"
import RefundDetails from "../pages/Transactions/RefundDetails"

// Notifications
import PushNotifications from "../pages/Notifications/PushNotifications"
import Emails from "../pages/Notifications/Emails"

// Bulk Delivery
import PendingVerifications from "../pages/Bulk Delivery/PendingVerifications"
import BulkDelivery from "../pages/Bulk Delivery/BulkDelivery"

// Couriers
import Courier from "../pages/Courier/Courier"
import CourierDetails from "../pages/Courier/CourierDetails"
import Tracking from "../pages/Tracking/Tracking"
import UserDevice from "../pages/senders/UserDevice"
import PartnerDevice from "../pages/Partner/PartnerDevice"
import CreateUser from "../pages/senders/CreateUser"

// hubs
import CreateHub from "../pages/Delivery Hub/CreateHub"
import DeliveryHub from "../pages/Delivery Hub/DeliveryHub"
import DeliveryHubDetails from "../pages/Delivery Hub/DeliveryHubDetails"
import CreateHubs from "../pages/Hubs/CreateHubs"
import Hubs from "../pages/Hubs/Hubs"
import HubDetails from "../pages/Hubs/HubDetails"

// centers
import Centers from "../pages/centers/Centers"
import CreateCenters from "../pages/centers/CreateCenters"
import CenterDetails from "../pages/centers/CenterDetails"

// sweepers
import CreateSweepers from "../pages/sweepers/CreateSweepers"
import Sweepers from "../pages/sweepers/Sweepers"
import SweeperDetails from "../pages/sweepers/SweeperDetails"

// corporate client
import CorporateClient from "../pages/Corporate Client/CorporateClient"
import CorporateClientDetails from "../pages/Corporate Client/CorporateClientDetails"

// tier
import Tier from "../pages/Tier/Tier"
import TierDetails from "../pages/Tier/TierDetails"
import CreateTier from "../pages/Tier/CreateTier"

// connection fee
import ConnectionFee from "../pages/Connection Fee/ConnectionFee"
import Determinant from "../pages/Determinant/Determinant"
import Promo from "../pages/Promo/Promo"

// activity logs
import ActivityLogs from "../pages/Activity Logs/ActivityLogs"
import ActivityLogDetails from "../pages/Activity Logs/ActivityLogDetails"

// suggested locations
import SuggestedLocations from "../pages/Suggested Locations/SuggestedLocations"




const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Senders
  { path: "/senders", component: AllUsers },
  { path: "/senders/:id", component: UsersProfile },
  { path: "/sender-device", component: UserDevice },

  // Partners
  { path: "/partners", component: Partners },
  { path: "/partner/:id", component: PartnerDetails },
  { path: "/onboard/:id", component: Onboarding },
  { path: "/partner-device", component: PartnerDevice },

  // Couriers
  { path: "/couriers", component: Courier },
  { path: "/courier/:id", component: CourierDetails },

  // Orders
  { path: "/all-orders", component: AllOrders },
  { path: "/order/:id", component: OrderDetails },
  

  // Delivery Hub
  { path: "/create-hub", component: CreateHub },
  { path: "/delivery-hub", component: DeliveryHub },
  { path: "/hubs", component: Hubs },
  { path: "/hubs/:id", component: HubDetails },
  { path: "/delivery-hub/:id", component: DeliveryHubDetails },
  
  // Hubs
  { path: "/create-hubs", component: CreateHubs },
  { path: "/centers", component: Centers },
  
  // Centers
  { path: "/create-centers", component: CreateCenters },
  { path: "/centers/:id", component: CenterDetails },
  
  // Sweepers
  { path: "/sweepers", component: Sweepers },
  { path: "/create-sweepers", component: CreateSweepers },
  { path: "/sweepers/:id", component: SweeperDetails },

  // Corporate Client
  { path: "/corporates", component: CorporateClient },
  { path: "/corporates/:id", component: CorporateClientDetails },
  
  // Determinant
  { path: "/determinant", component: Determinant },

  // Promo
  { path: "/promo", component: Promo },
  
  
  // Tier
  { path: "/tiers", component: Tier },
  { path: "/tiers/:id", component: TierDetails },
  { path: "/create-tier", component: CreateTier },
  
  // connection fee
  { path: "/connection-fee", component: ConnectionFee },
  
  // Activity logs
  { path: "/activity-logs", component: ActivityLogs },
  { path: "/activity-logs/:id", component: ActivityLogDetails },
  
  // Suggested Locations
  { path: "/suggested-locations", component: SuggestedLocations },

  // Transactions
  { path: "/all-transactions", component: AllTransactions },
  { path: "/transaction/:id", component: TransactionDetails },
  { path: "/refund", component: Refund },
  { path: "/refund/:id", component: RefundDetails },
  
  // Notifications
  { path: "/push-notifications", component: PushNotifications },
  { path: "/emails", component: Emails },
  
  // Bulk Delivery
  { path: "/pending-verifications", component: PendingVerifications },
  { path: "/bulk-delivery", component: BulkDelivery },
  
  // Tracking
  { path: "/track", component: Tracking },

  { path: "/create-user", component: CreateUser },
  
  // // //profile
  { path: "/profile", component: UserProfile },
  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }

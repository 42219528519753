import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"

import { Link, Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BsThreeDots } from "react-icons/bs"
import { CiEdit } from "react-icons/ci"
import { HiOutlineTrash } from "react-icons/hi2"

import baseURL from "../../utils"

const Promo = () => {
  const [promos, setPromos] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [itemName, setItemName] = useState("")
  const [isFlat, setIsflat] = useState(true)
  const [isActive, setIsActive] = useState(true)
  const [percentage, setPercentage] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [size, setSize] = useState("")
  const [deliveryDays, setDeliveryDays] = useState([])
  const [applicablePrice, setApplicablePrice] = useState(0)
  const [fromType, setFromType] = useState(null)
  const [toType, setToType] = useState(null)
  const [fromId, setFromId] = useState("")
  const [toId, setToId] = useState("")
  const [centers, setCenters] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [singlePromo, setSinglePromo] = useState({
    item_name: "",
    applicable_price: 0,
    percentage: 0,
    current_price: 0,
    end_date: null,
    from: null,
    id: 1,
    is_active: true,
    is_flat: false,
    level_from_id: null,
    level_from_type: null,
    level_to_id: null,
    level_to_type: null,
    medium: null,
    name: "",
    size: null,
    start_date: null,
    time_delivery_days: null,
    to: null,
  })
  const [promoId, setPromoId] = useState("")
  const [states, setStates] = useState([])
  const [regions, setRegions] = useState([])

  // fetch promos
  useEffect(() => {
    const fetchPromos = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/promos`, options)
        const promos = await response.json()
        setPromos(promos.data)
      } catch (error) {
        toast.error(error.message)
      }
    }

    fetchPromos()
  }, [])

  // Fetch centers
  useEffect(() => {
    const fetchCenter = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/centers`, options)
        const centers = await response.json()
        setCenters(centers.data.centers)
        setLoading(false)
      } catch (error) {
        toast.error(error.message)
      }
    }

    fetchCenter()
  }, [])

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then(data => data.json())
      .then(response => {
        setStates(response.data)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  // Fetch regions
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/regions`, options)
      .then(data => data.json())
      .then(response => {
        setRegions(response.data)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const handleCheckboxChange = day => {
    setDeliveryDays(
      prev =>
        prev.includes(day)
          ? prev.filter(d => d !== day) // Remove if already selected
          : [...prev, day] // Add if not selected
    )
  }

  //  update promo
  const updatePromos = async promoId => {
    const formatDate = inputDate => {
      const date = new Date(inputDate)
      if (isNaN(date)) throw new Error("Invalid date format")

      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }

    try {
      setDisabled(true)
      setButtonLoad(true)

      const options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
        body: JSON.stringify({
          name: singlePromo.name,
          applicable_price: singlePromo.applicable_price
            .replace(/,/g, "")
            .split(".")[0],
          is_flat: isFlat,
          is_active: isActive,
          percentage: singlePromo.percentage,
          start_date: formatDate(singlePromo.start_date),
          end_date: formatDate(singlePromo.end_date),
          size: singlePromo.size,
          medium: singlePromo.medium,
          time_delivery_days: singlePromo.time_delivery_days,
          level_from_type: singlePromo.level_from_type,
          level_to_type: singlePromo.level_to_type,
          level_from_id: singlePromo.level_from_id,
          level_to_id: singlePromo.level_to_id,
        }),
      }

      const response = await fetch(
        `${baseURL}/api/v1/admin/promos/${promoId}`,
        options
      )
      const result = await response.json()

      if (result.errors) {
        toast.error(result.message)
        setDisabled(false)
        setButtonLoad(false)
      } else {
        toast.success(result.message)
        setButtonLoad(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    } catch (error) {
      toast.error(error.message)
      setDisabled(false)
      setButtonLoad(false)
    }
  }

  // create promo
  const createPromo = async () => {
    // Utility function for formatting dates or returning null if not set
    const formatDateTime = date =>
      date ? new Date(date).toISOString().replace("T", " ").split(".")[0] : null

    if (applicablePrice !== 0) {
      const formattedPrice = applicablePrice.replace(/,/g, "").split(".")[0]
      setApplicablePrice(formattedPrice)
    } else {
      setApplicablePrice(applicablePrice)
    }

    try {
      const formattedStartDateTime = formatDateTime(startDate)
      const formattedEndDateTime = formatDateTime(endDate)

      setDisabled(true)
      setButtonLoad(true)

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        body: JSON.stringify({
          name: itemName,
          is_flat: isFlat,
          is_active: isActive,
          percentage,
          start_date: formattedStartDateTime,
          end_date: formattedEndDateTime,
          size,
          medium: null,
          time_delivery_days: deliveryDays,
          applicable_price: applicablePrice,
          level_from_type: fromType,
          level_to_type: toType,
          level_from_id: fromId,
          level_to_id: toId,
        }),
      }

      const response = await fetch(`${baseURL}/api/v1/admin/promos`, options)
      const result = await response.json()

      if (response.ok) {
        toast.success(result.message)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        toast.error(
          result.message || "An error occurred while creating the promo."
        )
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`)
    } finally {
      setDisabled(false)
      setButtonLoad(false)
    }
  }

  // Delete promotion
  const deletePromo = async promoId => {
    if (confirm("Are you sure you want to delete this promotion?")) {
      setDisabled(true)
      setButtonLoad(false)
      const options = {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
      }
      fetch(`${baseURL}/api/v1/admin/promos/${promoId}`, options)
        .then(data => data.json())
        .then(response => {
          console.log(response)
          if (response.errors) {
            toast.error(response.message)
            setDisabled(false)
            setButtonLoad(false)
          } else {
            toast.success(response.message)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
        .catch(error => {
          toast.error(error.message)
          setButtonLoad(false)
          setDisabled(false)
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promo | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4 flex justify-content-between align-items-centerx">
          <Col md={7}>
            <h4 className="dashboard-head">Promo</h4>
          </Col>
          <Col md={5} className="d-flex justify-content-end gap-2">
            <input
              type="text"
              placeholder="Search"
              // value={search}
              // onChange={e => setSearch(e.target.value)}
            />
            <button
              className="connect-btn save-btn"
              data-bs-toggle="modal"
              data-bs-target="#itemModal"
              style={{
                width: "fit-content",
                fontSize: "12px",
                whiteSpace: "nowrap",
                padding: "8.352px 15.034px",
              }}
            >
              Add new promo
            </button>
            <div
              className="modal fade"
              id="itemModal"
              tabIndex="-1"
              aria-labelledby="itemModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="itemModalLabel"
                      style={{
                        color: "#0D172B",
                        fontSize: "15px",
                      }}
                    >
                      Add new promo
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body bg-white">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Promo name
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={itemName}
                            onChange={e => setItemName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Applicable Price
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={applicablePrice}
                            onChange={e => {
                              const re = /^[0-9\b]+$/
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setApplicablePrice(e.target.value)
                              }
                            }}
                            required
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Percentage
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={percentage}
                            onChange={e => {
                              const re = /^[0-9\b]+$/
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setPercentage(e.target.value)
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">Size</label>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={size}
                            onChange={e => {
                              setSize(e.target.value)
                            }}
                          >
                            <option defaultValue>Select size</option>
                            <option defaultValue="small">small</option>
                            <option defaultValue="medium">medium</option>
                            <option defaultValue="large">large</option>
                          </select>
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Delivery Days
                            <span className="connect-required">*</span>
                          </label>
                          <div>
                            {[1, 2, 3].map(day => (
                              <label key={day} style={{ marginRight: "10px" }}>
                                <input
                                  type="checkbox"
                                  value={day}
                                  checked={deliveryDays.includes(day)}
                                  onChange={() => handleCheckboxChange(day)}
                                />
                                {day} day{day > 1 && "s"}
                              </label>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">Start Date</label>
                          <input
                            type="datetime-local"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">End date</label>
                          <input
                            type="datetime-local"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">From</label>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={fromType}
                            onChange={e => {
                              setFromType(e.target.value)
                            }}
                          >
                            <option defaultValue>Select type</option>
                            <option defaultValue="center">center</option>
                            <option defaultValue="state">state</option>
                            <option defaultValue="region">region</option>
                          </select>
                        </div>
                        {fromType === "center" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select Center
                            </label>
                            <select
                              required
                              type="text"
                              value={fromId}
                              className="form-select shadow-none"
                              onChange={e => {
                                setFromId(e.target.value)
                                console.log(fromId)
                              }}
                            >
                              <option value="">Select center</option>
                              {loading
                                ? "Loading..."
                                : centers?.map(center => {
                                    const { name, id } = center
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : fromType === "state" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select State
                            </label>
                            <select
                              required
                              type="text"
                              value={fromId}
                              className="form-select shadow-none"
                              onChange={e => setFromId(e.target.value)}
                            >
                              <option value="">select State</option>
                              {loading
                                ? "Loading..."
                                : states?.map(state => {
                                    const { name, id } = state
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : fromType === "region" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select Region
                            </label>
                            <select
                              required
                              type="text"
                              value={fromId}
                              className="form-select shadow-none"
                              onChange={e => setFromId(e.target.value)}
                            >
                              <option value="">select State</option>
                              {loading
                                ? "Loading..."
                                : regions?.map(region => {
                                    const { name, id } = region
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : null}
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">To</label>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={toType}
                            onChange={e => {
                              setToType(e.target.value)
                            }}
                          >
                            <option defaultValue>Select type</option>
                            <option defaultValue="center">center</option>
                            <option defaultValue="state">state</option>
                            <option defaultValue="region">region</option>
                          </select>
                        </div>
                        {toType === "center" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select Center
                            </label>
                            <select
                              required
                              type="text"
                              value={toId}
                              className="form-select shadow-none"
                              onChange={e => setToId(e.target.value)}
                            >
                              <option value="">Select center</option>
                              {loading
                                ? "Loading..."
                                : centers?.map(center => {
                                    const { name, id } = center
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : toType === "state" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select State
                            </label>
                            <select
                              required
                              type="text"
                              value={toId}
                              className="form-select shadow-none"
                              onChange={e => setToId(e.target.value)}
                            >
                              <option value="">select State</option>
                              {loading
                                ? "Loading..."
                                : states?.map(state => {
                                    const { name, id } = state
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : toType === "region" ? (
                          <div className="col-md-12 mb-4">
                            <label className="connect-label">
                              Select Region
                            </label>
                            <select
                              required
                              type="text"
                              value={toId}
                              className="form-select shadow-none"
                              onChange={e => setToId(e.target.value)}
                            >
                              <option value="">select Region</option>
                              {loading
                                ? "Loading..."
                                : regions?.map(region => {
                                    const { name, id } = region
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="connect-footer">
                    <button
                      type="button"
                      className="connect-btn"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      disabled={disabled}
                      className={`connect-btn save-btn ${
                        buttonLoad && "opacity-50"
                      }`}
                      onClick={() => createPromo()}
                    >
                      {buttonLoad ? (
                        <div
                          className="spinner-border text-light mx-3"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Create Promo"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2"></Row>
                      {promos?.length !== 0 ? (
                        <div className="table-responsive">
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Applicable price</th>
                                <th>Date added</th>
                                <th>Status</th>
                                <th>
                                  <BsThreeDots />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {promos?.map(price => {
                                const {
                                  uuid,
                                  applicable_price,
                                  is_active,
                                  name,
                                  created_at,
                                } = price
                                return (
                                  <tr key={uuid}>
                                    <td>{name}</td>
                                    <td>{applicable_price}</td>
                                    <td>{created_at}</td>
                                    <td
                                      style={{
                                        color:
                                          is_active === true
                                            ? "#29CC97"
                                            : "#FEC400",
                                      }}
                                    >
                                      {is_active ? "ACTIVE" : "NOT ACTIVE"}
                                    </td>
                                    <td>
                                      <div className="dropdown">
                                        <button
                                          className="btn shadow-none bg-transparent border"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          more
                                        </button>
                                        <div
                                          className="dropdown-menu border-0"
                                          style={{
                                            position: "relative",
                                            zIndex: "2000",
                                            width: "160px",
                                            padding: "8px 12px",
                                            borderRadius: "10px",
                                            boxShadow:
                                              "8px 12px 24px 0px rgba(90, 107, 133, 0.10)",
                                          }}
                                        >
                                          <div className="d-flex justify-content-center align-items-center px-3"></div>
                                          <div
                                            className="d-flex align-items-center gap-2 m-2"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => {
                                              setSinglePromo(price)
                                              setPromoId(price?.uuid)
                                              console.log(price)
                                            }}
                                          >
                                            <CiEdit
                                              size={16}
                                              style={{ color: "#718096" }}
                                            />{" "}
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                color: "#6D8198",
                                                fontWeight: "500",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Edit
                                            </span>
                                          </div>
                                          <div
                                            className="d-flex align-items-center gap-2 m-2"
                                            onClick={() =>
                                              deletePromo(price?.uuid)
                                            }
                                          >
                                            <HiOutlineTrash
                                              size={16}
                                              style={{ color: "#718096" }}
                                            />{" "}
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                color: "#6D8198",
                                                fontWeight: "500",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Delete
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="modal fade"
                                        id="exampleModal"
                                        tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                                style={{
                                                  color: "#0D172B",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                Update Promo
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body bg-white">
                                              <div className="container">
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Promo name
                                                      <span className="connect-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={singlePromo?.name}
                                                      onChange={e =>
                                                        setSinglePromo({
                                                          ...singlePromo,
                                                          name: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Applicable Price
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={
                                                        singlePromo.applicable_price
                                                      }
                                                      onChange={e => {
                                                        setSinglePromo({
                                                          ...singlePromo,
                                                          applicable_price:
                                                            e.target.value,
                                                        })
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Percentage
                                                      <span className="connect-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={
                                                        singlePromo?.percentage
                                                      }
                                                      onChange={e => {
                                                        const re = /^[0-9\b]+$/
                                                        if (
                                                          e.target.value ===
                                                            "" ||
                                                          re.test(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          setPercentage(
                                                            e.target.value
                                                          )
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Size
                                                    </label>
                                                    <select
                                                      className="form-select form-select-sm"
                                                      aria-label=".form-select-sm example"
                                                      value={singlePromo?.size}
                                                      onChange={e => {
                                                        setSize(e.target.value)
                                                      }}
                                                    >
                                                      <option defaultValue>
                                                        Select size
                                                      </option>
                                                      <option defaultValue="small">
                                                        small
                                                      </option>
                                                      <option defaultValue="medium">
                                                        medium
                                                      </option>
                                                      <option defaultValue="large">
                                                        large
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Delivery Days
                                                      <span className="connect-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <div>
                                                      {[1, 2, 3].map(day => (
                                                        <label
                                                          key={day}
                                                          style={{
                                                            marginRight: "10px",
                                                          }}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            value={singlePromo}
                                                            checked={singlePromo.time_delivery_days?.includes(
                                                              day
                                                            )}
                                                            onChange={() =>
                                                              handleCheckboxChange(
                                                                day
                                                              )
                                                            }
                                                          />
                                                          {day} day
                                                          {day > 1 && "s"}
                                                        </label>
                                                      ))}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Start Date
                                                    </label>
                                                    <input
                                                      type="datetime-local"
                                                      value={
                                                        singlePromo?.start_date
                                                      }
                                                      onChange={e =>
                                                        setStartDate(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      End date
                                                    </label>
                                                    <input
                                                      type="datetime-local"
                                                      value={
                                                        singlePromo?.end_date
                                                      }
                                                      onChange={e =>
                                                        setEndDate(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      From
                                                    </label>
                                                    <select
                                                      className="form-select form-select-sm"
                                                      aria-label=".form-select-sm example"
                                                      value={
                                                        singlePromo?.level_from_type
                                                      }
                                                      onChange={e => {
                                                        setFromType(
                                                          e.target.value
                                                        )
                                                      }}
                                                    >
                                                      <option defaultValue>
                                                        {
                                                          singlePromo?.level_from_type
                                                        }
                                                      </option>
                                                      <option defaultValue="center">
                                                        center
                                                      </option>
                                                      <option defaultValue="state">
                                                        state
                                                      </option>
                                                      <option defaultValue="region">
                                                        region
                                                      </option>
                                                    </select>
                                                  </div>
                                                  {singlePromo?.level_from_type ===
                                                  "center" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select Center
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_from_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e => {
                                                          setFromId(
                                                            e.target.value
                                                          )
                                                          console.log(fromId)
                                                        }}
                                                      >
                                                        <option value="">
                                                          Select center
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : centers?.map(
                                                              center => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = center
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : singlePromo?.level_from_type ===
                                                    "state" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select State
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_from_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e =>
                                                          setFromId(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          select State
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : states?.map(
                                                              state => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = state
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : singlePromo?.level_from_type ===
                                                    "region" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select Region
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_from_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e =>
                                                          setFromId(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          select State
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : regions?.map(
                                                              region => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = region
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : null}
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      To
                                                    </label>
                                                    <select
                                                      className="form-select form-select-sm"
                                                      aria-label=".form-select-sm example"
                                                      value={
                                                        singlePromo?.level_to_type
                                                      }
                                                      onChange={e => {
                                                        setToType(
                                                          e.target.value
                                                        )
                                                      }}
                                                    >
                                                      <option defaultValue>
                                                        Select type
                                                      </option>
                                                      <option defaultValue="center">
                                                        center
                                                      </option>
                                                      <option defaultValue="state">
                                                        state
                                                      </option>
                                                      <option defaultValue="region">
                                                        region
                                                      </option>
                                                    </select>
                                                  </div>
                                                  {singlePromo?.level_to_type ===
                                                  "center" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select Center
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_to_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e =>
                                                          setToId(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          Select center
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : centers?.map(
                                                              center => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = center
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : singlePromo?.level_to_type ===
                                                    "state" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select State
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_to_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e =>
                                                          setToId(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          select State
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : states?.map(
                                                              state => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = state
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : singlePromo?.level_to_type ===
                                                    "region" ? (
                                                    <div className="col-md-12 mb-4">
                                                      <label className="connect-label">
                                                        Select Region
                                                      </label>
                                                      <select
                                                        required
                                                        type="text"
                                                        value={
                                                          singlePromo?.level_to_id
                                                        }
                                                        className="form-select shadow-none"
                                                        onChange={e =>
                                                          setToId(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          select Region
                                                        </option>
                                                        {loading
                                                          ? "Loading..."
                                                          : regions?.map(
                                                              region => {
                                                                const {
                                                                  name,
                                                                  id,
                                                                } = region
                                                                return (
                                                                  <option
                                                                    key={id}
                                                                    value={id}
                                                                  >
                                                                    {name}
                                                                  </option>
                                                                )
                                                              }
                                                            )}
                                                      </select>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="connect-footer">
                                              <button
                                                type="button"
                                                className="connect-btn"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                              <button
                                                disabled={disabled}
                                                className={`connect-btn save-btn ${
                                                  buttonLoad && "opacity-50"
                                                }`}
                                                onClick={() =>
                                                  updatePromos(promoId)
                                                }
                                              >
                                                {buttonLoad ? (
                                                  <div
                                                    className="spinner-border text-light mx-3"
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  "Update Promo"
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-4">
                          <h3 className="fw-bold">Oops, No Promo</h3>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Promo

import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"

import { Link, Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BsThreeDots } from "react-icons/bs"
import { CiEdit } from "react-icons/ci"
import { HiOutlineTrash } from "react-icons/hi2"

import baseURL from "../../utils"

const Determinant = () => {
  const [prices, setPrices] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [itemName, setItemName] = useState("")
  const [basePrice, setBasePrice] = useState("")
  const [currentPrice, setCurrentPrice] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [singleItem, setSingleItem] = useState({
    item_name: "",
    base_price: 0,
    current_price: 0,
  })
  const [commodityId, setCommodityId] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/commodity-prices`,
          options
        )
        const prices = await response.json()
        setPrices(prices.data)
        console.log(prices)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  //  update commodity price
  const updatePrices = async commodityId => {
    setDisabled(true)
    setButtonLoad(true)
    const options = {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        name: singleItem.name,
        base_price: singleItem.base_price.replace(/,/g, "").split(".")[0],
        current_price: singleItem.current_price.replace(/,/g, "").split(".")[0],
      }),
    }
    fetch(`${baseURL}/api/v1/admin/commodity-prices/${commodityId}`, options)
      .then(data => data.json())
      .then(response => {
        console.log(response)
        if (response.errors) {
          toast.error(response.message)
          setDisabled(false)
          setButtonLoad(false)
        } else {
          toast.success(response.message)
          setButtonLoad(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      })
      .catch(error => {
        toast.error(error.message)
        setDisabled(false)
        setButtonLoad(false)
      })
  }

  // create commodity price
  const createPrice = async () => {
    setDisabled(true)
    setButtonLoad(true)
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        name: itemName,
        base_price: basePrice.replace(/,/g, "").split(".")[0],
        current_price: currentPrice.replace(/,/g, "").split(".")[0],
      }),
    }
    fetch(`${baseURL}/api/v1/admin/commodity-prices`, options)
      .then(data => data.json())
      .then(response => {
        console.log(response)
        if (response.errors) {
          toast.error(response.message)
          setDisabled(false)
          setButtonLoad(false)
        } else {
          toast.success(response.message)
          setButtonLoad(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      })
      .catch(error => {
        toast.error(error.message)
        setButtonLoad(false)
        setDisabled(false)
      })
  }

  // Delete commodity price
  const deletePrice = async commodityId => {
    if (confirm("Are you sure you want to delete this commodity price?")) {
      setDisabled(true)
      setButtonLoad(false)
      const options = {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
      }
      fetch(`${baseURL}/api/v1/admin/commodity-prices/${commodityId}`, options)
        .then(data => data.json())
        .then(response => {
          console.log(response)
          if (response.errors) {
            toast.error(response.message)
            setDisabled(false)
            setButtonLoad(false)
          } else {
            toast.success(response.message)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
        .catch(error => {
          toast.error(error.message)
          setButtonLoad(false)
          setDisabled(false)
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Price Determinant | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4 flex justify-content-between align-items-centerx">
          <Col md={7}>
            <h4 className="dashboard-head">Price Determinant</h4>
          </Col>
          <Col md={5} className="d-flex justify-content-end gap-2">
            <input
              type="text"
              placeholder="Search"
              // value={search}
              // onChange={e => setSearch(e.target.value)}
            />
            <button
              className="connect-btn save-btn"
              data-bs-toggle="modal"
              data-bs-target="#itemModal"
              style={{
                width: "fit-content",
                fontSize: "12px",
                whiteSpace: "nowrap",
                padding: "8.352px 15.034px",
              }}
            >
              Add new item
            </button>
            <div
              className="modal fade"
              id="itemModal"
              tabIndex="-1"
              aria-labelledby="itemModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="itemModalLabel"
                      style={{
                        color: "#0D172B",
                        fontSize: "15px",
                      }}
                    >
                      Add new item
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body bg-white">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Item name
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={itemName}
                            onChange={e => setItemName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Base Price
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={basePrice}
                            onChange={e => {
                              const re = /^[0-9\b]+$/
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setBasePrice(e.target.value)
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="connect-label">
                            Current Price{" "}
                            <span className="connect-required">*</span>
                          </label>
                          <input
                            type="text"
                            value={currentPrice}
                            onChange={e => {
                              const re = /^[0-9\b]+$/
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setCurrentPrice(e.target.value)
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="connect-footer">
                    <button
                      type="button"
                      className="connect-btn"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      disabled={disabled}
                      className={`connect-btn save-btn ${
                        buttonLoad && "opacity-50"
                      }`}
                      onClick={() => createPrice()}
                    >
                      {buttonLoad ? (
                        <div
                          className="spinner-border text-light mx-3"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Save Details"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2"></Row>
                      {prices?.length !== 0 ? (
                        <div className="table-responsive">
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Base price</th>
                                <th>Current price</th>
                                <th>Date added</th>
                                <th>
                                  <BsThreeDots />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {prices?.map(price => {
                                const {
                                  uuid,
                                  base_price,
                                  current_price,
                                  name,
                                  created_at,
                                } = price
                                return (
                                  <tr key={uuid}>
                                    <td>{name}</td>
                                    <td>{base_price}</td>
                                    <td>{current_price}</td>
                                    <td>{created_at}</td>
                                    <td>
                                      <div className="dropdown">
                                        <button
                                          className="btn shadow-none bg-transparent border"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          more
                                        </button>
                                        <div
                                          className="dropdown-menu border-0"
                                          style={{
                                            position: "relative",
                                            zIndex: "2000",
                                            width: "160px",
                                            padding: "8px 12px",
                                            borderRadius: "10px",
                                            boxShadow:
                                              "8px 12px 24px 0px rgba(90, 107, 133, 0.10)",
                                          }}
                                        >
                                          <div className="d-flex justify-content-center align-items-center px-3"></div>
                                          <div
                                            className="d-flex align-items-center gap-2 m-2"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => {
                                              setSingleItem(price)
                                              setCommodityId(price?.uuid)
                                            }}
                                          >
                                            <CiEdit
                                              size={16}
                                              style={{ color: "#718096" }}
                                            />{" "}
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                color: "#6D8198",
                                                fontWeight: "500",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Edit
                                            </span>
                                          </div>
                                          <div
                                            className="d-flex align-items-center gap-2 m-2"
                                            onClick={() =>
                                              deletePrice(price?.uuid)
                                            }
                                          >
                                            <HiOutlineTrash
                                              size={16}
                                              style={{ color: "#718096" }}
                                            />{" "}
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                color: "#6D8198",
                                                fontWeight: "500",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Delete
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="modal fade"
                                        id="exampleModal"
                                        tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                                style={{
                                                  color: "#0D172B",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                Edit item
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body bg-white">
                                              <div className="container">
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Item name
                                                      <span className="connect-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={singleItem?.name}
                                                      onChange={e =>
                                                        setSingleItem({
                                                          ...singleItem,
                                                          name: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Base Price
                                                      <span className="connect-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={
                                                        singleItem?.base_price
                                                      }
                                                      onChange={e =>
                                                        setSingleItem({
                                                          ...singleItem,
                                                          base_price:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12 mb-4">
                                                    <label className="connect-label">
                                                      Current price
                                                    </label>
                                                    <input
                                                      type="text"
                                                      value={
                                                        singleItem?.current_price
                                                      }
                                                      onChange={e =>
                                                        setSingleItem({
                                                          ...singleItem,
                                                          current_price:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="connect-footer">
                                              <button
                                                type="button"
                                                className="connect-btn"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                              <button
                                                disabled={disabled}
                                                className={`connect-btn save-btn ${
                                                  buttonLoad && "opacity-50"
                                                }`}
                                                onClick={() =>
                                                  updatePrices(commodityId)
                                                }
                                              >
                                                {buttonLoad ? (
                                                  <div
                                                    className="spinner-border text-light mx-3"
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  "Save Details"
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-4">
                          <h3 className="fw-bold">Oops, No Commodity Price</h3>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Determinant
